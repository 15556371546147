var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import InfosetsMemberActionsMenu from '@/modules/infosets/components/InfosetsMemberActionsMenu.vue';
import InfosetMembersMenuContainer from '@/modules/infosets/containers/InfosetsMembersMenu.container.vue';
import EventBus from '@/shared/event-bus';
import Input from 'components/Input.vue';
import Star from 'components/Star.vue';
import UserAvatar from 'components/UserAvatar.vue';
import IconEmail from '@/components/Icons/IconEmail.vue';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import InfosetExportModalContainer from '../containers/Exportations/InfosetExportModal.container.vue';
import InfosetRemoveModal from './InfosetRemoveModal.vue';
import InfosetsDropdown from './InfosetsDropdownMenu.vue';
import AccuracyDataFeedbackModal from './AccuracyDataFeedbackModal.vue';
import InfosetsMoreListMembersMenuContainer from '@/modules/infosets/containers/InfosetsMoreListMembersMenu.container.vue';
import ModalUserHasNotPermission from '@/components/ModalUserHasNotPermission.vue';
import { connect } from '@/overmind';
let InfosetsTableRow = class InfosetsTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.infosetItem = {
            id: '',
            name: '',
            members: [],
            updatedAt: 1,
            createdAt: 1,
            star: '',
            author: { id: '', name: '', email: '' },
        };
        this.filteredAvailableMembers = [];
        this.selectedMembers = [];
        this.infosetMembers = this.infoset.members || [];
        this.memberNames = [];
        this.newInfosetName = '';
        this.showModalAddUser = false;
        this.showModalEditInfoset = false;
        this.showModalRemoveInfoset = false;
        this.showExportModal = false;
        this.showHasNotPermissionModal = false;
        this.showMemberDetails = false;
        this.showMemberActionsMenu = false;
        this.edittingInfoset = false;
        this.infosetName = '';
        this.selectedMember = null;
        this.openAccuracyFeedback = false;
        this.hasError = false;
        this.errorMessage = '';
        this.emailsNoValidatedYet = false;
        this.phonesNoValidatedYet = false;
        this.emailsInValidation = false;
        this.phonesInValidation = false;
    }
    checkChanges(infoset) {
        this.infosetItem = infoset;
        this.infosetName = infoset.name;
    }
    // test
    get canShare() {
        // @ts-ignore
        return this.$auth.check(['admin', 'owner', 'manager', 'customer_success']);
    }
    get getCreationDate() {
        moment.locale('pt-BR');
        return this.infosetItem.createdAt
            ? moment(new Date(this.infosetItem.createdAt), 'YYYYMMDD').fromNow()
            : '';
    }
    goToInfosetPage() {
        this.$router.push({
            name: 'infoset',
            params: { infosetId: this.infosetItem.id },
        });
        if (this.$parent)
            this.$parent.$emit('goToInfoset', this.infosetItem.id);
    }
    showEditInput() {
        this.edittingInfoset = true;
    }
    showStatsInfoset() {
        if (this.$parent)
            this.$parent.$emit('showStatsInfoset', this.infosetItem.id);
    }
    isDeleted(infoset) {
        return !!infoset.deletedAt;
    }
    starInfoset(value) {
        const stringValue = value === true ? 'true' : 'false';
        return { infosetId: this.infosetItem.id, star: stringValue };
    }
    confirmRemoveInfoset() {
        this.showModalRemoveInfoset = false;
        if (this.$parent)
            this.$parent.$emit('onRemoveInfoset', this.infosetItem.id);
    }
    getAvailableMembers() {
        //EventBus.$emit('ALL_MEMBERS_REQUEST');
        if (this.$parent)
            this.$parent.$emit('requestAllUsers', true);
    }
    goToInfosetAsMember(key) {
        this.$router.push({
            name: 'infoset',
            params: { infosetId: this.infosetItem.id },
            query: { view_as: this.infosetItem.members[key].id },
        });
    }
    async handleSpeedmailAction() {
        //@ts-ignore
        const result = await this.actionsShop.getAppPermission('speedmail');
        const hasSpeedmail = result.data.have_app;
        const handleRoute = hasSpeedmail
            ? `/shop/config/speedmail-app?infosetId=${this.infoset.id}`
            : '/shop/overview';
        this.$router.push(handleRoute);
    }
    onInfosetChange(val, _oldVal) {
        if (val) {
            this.buildInitialBadges();
        }
    }
    setNewInfosetName(name) {
        this.newInfosetName = name;
    }
    confirmEditInfoset() {
        if (this.newInfosetName && this.validateInfosetName() && this.$parent) {
            this.$parent.$emit('onEditInfoset', {
                infosetId: this.infosetItem.id,
                newValues: {
                    name: this.newInfosetName,
                },
            });
            this.showModalEditInfoset = false;
            this.edittingInfoset = false;
        }
        //@ts-ignore
        else
            return this.$refs.editInput.$refs.qinput.validate();
    }
    validateInfosetName() {
        return (this.newInfosetName.length > 0 &&
            this.newInfosetName.length <= 60 &&
            this.newInfosetName !== null &&
            this.newInfosetName !== '');
    }
    hasNoSpecialChars() {
        if (this.newInfosetName === '' || this.newInfosetName === null) {
            this.hasError = false;
            return;
        }
        const isValid = /^[a-zA-Z0-9\s/\\|\-]+$/.test(this.newInfosetName.trim());
        if (!isValid) {
            this.hasError = true;
            return 'Forneça um nome válido';
        }
        else {
            this.hasError = false;
            return;
        }
    }
    cancelEditInfosetName() {
        this.edittingInfoset = false;
        this.infosetName = this.infoset.name;
    }
    updateMembers({ member, event, }) {
        if (event) {
            this.infosetMembers.push(member);
        }
        else {
            const foundIndex = this.infosetMembers.findIndex(m => m.id === member.id);
            if (foundIndex > -1) {
                this.infosetMembers.splice(foundIndex, 1);
            }
        }
        EventBus.$emit('UPDATE_MEMBERS_REQUEST', this.infosetItem.id, this.infosetMembers);
    }
    buildInitialBadges() {
        if (this.infosetItem !== null) {
            if (this.infosetItem.members && this.infosetItem.members.length > 0) {
                let members = this.aggregateInfosetAuthorAsMember();
                this.memberNames = members
                    .slice(0, 3)
                    .map((member) => {
                    const names = member.name.split(' ');
                    const first = names[0].substr(0, 1).toUpperCase();
                    const last = names[names.length - 1].substr(0, 1).toUpperCase();
                    return first + last;
                });
            }
            else {
                if (this.infosetItem.author) {
                    if (this.infosetItem.author.name) {
                        const names = this.infosetItem.author.name.split(' ');
                        const first = names[0].substr(0, 1).toUpperCase();
                        const last = names[names.length - 1].substr(0, 1).toUpperCase();
                        const initials = first + last;
                        this.memberNames = this.memberNames.concat([initials]);
                    }
                }
            }
        }
        return '';
    }
    //
    handleAuthorName(infoset) {
        return infoset.author && infoset.author.name
            ? infoset.author.name
            : this.currentUser.name
                ? this.currentUser.name
                : '';
    }
    openExportModal() {
        //@ts-ignore
        if (this.userActions.canOpenExportModal()) {
            this.showExportModal = true;
            this.$emit('setCurrentInfoset', this.infosetItem.id);
        }
        else {
            this.showHasNotPermissionModal = true;
        }
    }
    aggregateInfosetAuthorAsMember() {
        let members = this.infosetItem && this.infosetItem.members
            ? this.infosetItem.members.slice(0, 2)
            : [];
        if (this.infoset.author) {
            members = members.concat([this.infoset.author]).reverse();
            return uniqBy(members, 'id');
        }
        return members;
    }
    get memberIsInfosetAuthor() {
        return (member) => {
            if (this.infoset.author && member)
                return this.infoset.author.id === member.id;
            return false;
        };
    }
    get maxAvatarCountVisibleAllowed() {
        if (this.infoset.members) {
            return this.infoset.members.slice(0, 2).length;
        }
        return 0;
    }
    get infosetMembersCount() {
        if (this.infoset.members) {
            return this.infoset.members.length;
        }
        return 0;
    }
    get infosetHasDataNoValidatedYet() {
        if (!this.infosetHasDataInValidation) {
            if (this.infoset.emailsValidationStatus) {
                if (this.infoset.emailsValidationStatus !== 'requested' &&
                    this.infoset.emailsValidationStatus === 'not initialized')
                    this.emailsNoValidatedYet = true;
            }
            if (this.infoset.phonesValidationStatus) {
                if (this.infoset.phonesValidationStatus !== 'requested' &&
                    this.infoset.phonesValidationStatus === 'not initialized')
                    this.phonesNoValidatedYet = true;
            }
        }
        return this.emailsNoValidatedYet || this.phonesNoValidatedYet;
    }
    get infosetHasDataInValidation() {
        if (this.infoset.emailsValidationStatus) {
            if (this.infoset.emailsValidationStatus === 'requested' ||
                (this.infoset.emailsValidationStatus !== 'not initialized' &&
                    this.infoset.emailsValidationStatus !== 'finished'))
                this.emailsInValidation = true;
        }
        if (this.infoset.phonesValidationStatus) {
            if (this.infoset.phonesValidationStatus === 'requested' ||
                (this.infoset.phonesValidationStatus !== 'not initialized' &&
                    this.infoset.phonesValidationStatus !== 'finished'))
                this.phonesInValidation = true;
        }
        return this.emailsInValidation || this.phonesInValidation;
    }
    async mounted() {
        this.buildInitialBadges();
    }
    handleShowMemberActionsMenu(member) {
        this.selectedMember = member;
        this.showMemberActionsMenu = !this.showMemberActionsMenu;
    }
    async openSearches() {
        // @ts-ignore
        const searches = this.infoset.search_ids;
        searches.forEach(async (search) => {
            window.open('/search/' + search.$oid, '_blank');
        });
    }
    openAccuracyDataFeedback() {
        this.openAccuracyFeedback = true;
    }
    recoverInfoSet(id) {
        this.$emit('recoverInfoSet', id);
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetsTableRow.prototype, "infoset", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetsTableRow.prototype, "availableMembers", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetsTableRow.prototype, "filteredMembers", void 0);
__decorate([
    Prop({ default: false })
], InfosetsTableRow.prototype, "loadingToExportCsv", void 0);
__decorate([
    Prop({ default: '' })
], InfosetsTableRow.prototype, "tokenPipeDrive", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetsTableRow.prototype, "currentUser", void 0);
__decorate([
    Watch('infoset', { deep: true, immediate: true })
], InfosetsTableRow.prototype, "checkChanges", null);
__decorate([
    Emit('starInfoset')
], InfosetsTableRow.prototype, "starInfoset", null);
__decorate([
    Watch('infoset')
], InfosetsTableRow.prototype, "onInfosetChange", null);
InfosetsTableRow = __decorate([
    Component(connect(({ actions }) => ({
        userActions: actions.users,
        actionsInfosets: actions.infosets,
        actionsShop: actions.shop,
    }), {
        name: 'InfosetsTableRow',
        components: {
            UserAvatar,
            Star,
            Input,
            IconEmail,
            InfosetMembersMenuContainer,
            InfosetsDropdown,
            InfosetRemoveModal,
            InfosetExportModalContainer,
            InfosetsMemberActionsMenu,
            InfosetsMoreListMembersMenuContainer,
            ModalUserHasNotPermission,
            AccuracyDataFeedbackModal,
        },
    }))
], InfosetsTableRow);
export default InfosetsTableRow;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
import Checkbox from '@/components/Checkbox.vue';
import { decisorsLevelIcons } from '@/shared/utils/Infosets.utils';
let ExportationsDraft = class ExportationsDraft extends Vue {
    constructor() {
        super(...arguments);
        this.toggleDecisors = false;
        this.allDecisorsLeveled = false;
        this.decisorsLeveled = [
            { name: 'Não classificado', checkbox: false },
            { name: 'Alto', checkbox: false },
            { name: 'Médio', checkbox: false },
            { name: 'Baixo', checkbox: false },
        ];
        this.decisorsWith = [
            { label: 'LinkedIn', checked: false, queryField: 'lkd_url' },
            { label: 'E-mail', checked: false, queryField: 'mchecker_email' },
        ];
        this.allDecisorsDepartment = false;
        this.decisorsDepartment = [
            { name: 'Administrativo', checkbox: false },
            { name: 'Comercial', checkbox: false },
            { name: 'Energia', checkbox: false },
            { name: 'Financeiro', checkbox: false },
            { name: 'Gestão', checkbox: false },
            { name: 'Industrial', checkbox: false },
            { name: 'Jurídico', checkbox: false },
            { name: 'Marketing', checkbox: false },
            { name: 'Proprietários', checkbox: false },
            { name: 'Recursos Humanos', checkbox: false },
            { name: 'Suprimento', checkbox: false },
            { name: 'TI', checkbox: false },
            { name: 'Operacional', checkbox: false },
            { name: 'Saúde', checkbox: false },
            { name: 'Outros', checkbox: false },
        ];
    }
    mounted() {
        this.setLevels();
        this.setDepartments();
        this.setTechnologies();
    }
    setLevels() {
        const levels = Object.values(this.filters.levels);
        this.decisorsLeveled.forEach((el, index) => {
            const checked = levels.includes(el.name);
            this.decisorsLeveled[index].checkbox = checked;
        });
    }
    setDepartments() {
        const departments = Object.values(this.filters.departments);
        this.decisorsDepartment.forEach((el, index) => {
            const checked = departments.includes(el.name);
            this.decisorsDepartment[index].checkbox = checked;
        });
    }
    setTechnologies() {
        const decisors_with = Object.values(this.filters.decisors_with);
        this.decisorsWith.forEach((el, index) => {
            const checked = decisors_with.includes(el.label);
            this.decisorsWith[index].checked = checked;
        });
    }
    changeLevels() {
        this.setLevels();
    }
    changeDepartments() {
        this.setDepartments();
    }
    changeTechnologies() {
        this.setTechnologies();
    }
    setAllLeveled(val) {
        this.decisorsLeveled.map(el => (el.checkbox = !val.checked));
        this.updateFiltersLeveled();
    }
    handleLeveledButton(value) {
        if (this.decisorsLeveled.every(el => el.checkbox === true)) {
            this.allDecisorsLeveled = true;
            return;
        }
        if (!value) {
            this.allDecisorsLeveled = false;
            return;
        }
    }
    setAllDepartment(val) {
        this.decisorsDepartment.map(el => {
            el.checkbox = !val.checked;
        });
        this.updateFiltersDepartment();
    }
    handleDepartmentButton(value) {
        if (this.decisorsDepartment.every(el => el.checkbox === true)) {
            this.allDecisorsDepartment = true;
            return;
        }
        if (!value) {
            this.allDecisorsDepartment = false;
            return;
        }
    }
    decisorsIcon(index) {
        return decisorsLevelIcons[index];
    }
    updateFiltersLeveled() {
        this.filters.levels = [];
        this.decisorsLeveled.forEach(el => el.checkbox == true ? this.filters.levels.push(el.name) : '');
        this.$emit('updateCalculate');
    }
    updateFiltersDepartment() {
        this.filters.departments = [];
        this.decisorsDepartment.forEach(el => el.checkbox == true ? this.filters.departments.push(el.name) : '');
        this.$emit('updateCalculate');
    }
    updateFiltersDecisionWith() {
        this.filters.decisors_with = [];
        this.decisorsWith.forEach(el => el.checked == true ? this.filters.decisors_with.push(el.label) : '');
        this.$emit('updateCalculate');
    }
    onChange(args) {
        const value = !args.checked;
        this.handleLeveledButton(value);
        this.updateFiltersLeveled();
    }
    onChangeFilter(args) {
        const value = !args.checked;
        this.handleDepartmentButton(value);
        this.updateFiltersDepartment();
    }
    onChangeDecisionWith(args) {
        this.updateFiltersDecisionWith();
    }
};
__decorate([
    PropSync('currentFilters', { default: () => { } })
], ExportationsDraft.prototype, "filters", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsDraft.prototype, "exportOption", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportationsDraft.prototype, "currentTab", void 0);
__decorate([
    Watch('filters.levels')
], ExportationsDraft.prototype, "changeLevels", null);
__decorate([
    Watch('filters.departments')
], ExportationsDraft.prototype, "changeDepartments", null);
__decorate([
    Watch('filters.decisors_with')
], ExportationsDraft.prototype, "changeTechnologies", null);
ExportationsDraft = __decorate([
    Component({
        name: 'ExportationsDraft',
        components: {
            Accordion,
            Checkbox,
        },
    })
], ExportationsDraft);
export default ExportationsDraft;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, PropSync, Emit, } from 'vue-property-decorator';
import ExportationsFields from './Filters/ExportationsFields.vue';
import ExportationsDecisors from './Filters/ExportationsDecisors.vue';
import ExportationsCompanyStatus from './Filters/ExportationsCompanyStatus.vue';
import ExportationsLeadStatus from './Filters/ExportationsLeadStatus.vue';
import ExportationsTypes from './Filters/ExportationsTypes.vue';
import InfosetExportErrorMessage from './InfosetExportErrorMessage.vue';
import { limitSheetAndCRM, exceedLimit } from '@/shared/utils/Infosets.utils';
import { connect } from '@/overmind';
import Tooltip from 'src/components/Tooltip.vue';
import { json } from 'overmind';
let InfosetExportContent = class InfosetExportContent extends Vue {
    constructor() {
        super(...arguments);
        this.removeCompaniesAlreadySended = true;
        this.exportTab = 'advanceExportation';
        this.decisors = true;
        this.updateTotvsData = false;
        this.shouldShowStatusPreCRMFilter = true;
        this.loadingEffect = true;
        this.cadenceNameSelected = '';
        this.ownerIdSelected = '';
        this.toggleSaveConfig = false;
    }
    get maxLimit() {
        const result = limitSheetAndCRM[this.currentTab] || limitSheetAndCRM['default'];
        return result;
    }
    listCadencesNames() {
        return this.candencesList && this.candencesList.length
            ? this.candencesList.map(c => c.name)
            : [];
    }
    get isSankhyaUser() {
        const userLogged = localStorage.getItem('userEmailLogged') || '';
        return !!userLogged.match('@sankhya'); // || !!userLogged.match('@speedio')
    }
    listOwnerNames() {
        if (this.isSankhyaUser) {
            return this.ownerCustomFieldList && this.ownerCustomFieldList.length
                ? this.ownerCustomFieldList.map(c => c.label)
                : [];
        }
        return this.ownerUsersList && this.ownerUsersList.length
            ? this.ownerUsersList.map(c => c.email)
            : [];
    }
    cadenceSelected() {
        const cadence = this.candencesList.find(c => c.name == this.cadenceNameSelected);
        if (cadence)
            return cadence.api_endpoint;
    }
    ownerSelected() {
        if (this.isSankhyaUser) {
            const owner = this.ownerCustomFieldList.find(c => c.label == this.ownerIdSelected);
            if (owner) {
                return {
                    id: owner.id,
                    email: owner.label,
                };
            }
        }
        const owner = this.ownerUsersList.find(c => c.email == this.ownerIdSelected);
        if (owner)
            return {
                id: null,
                email: owner.email,
            };
    }
    async fetchCompaniesInKanban() {
        // @ts-ignore
        const { data } = await this.effects.api.fetchCompaniesKanbanByStatus({
            infosetId: this.infoset_id,
            status: 'success',
            page: 1,
            query: '',
        });
        if (data && data.length > 0) {
            this.shouldShowStatusPreCRMFilter = true;
        }
        else {
            this.shouldShowStatusPreCRMFilter = false;
        }
        this.loadingEffect = false;
    }
    async mounted() {
        this.decisors = this.filters.decisors;
        this.toggleSaveConfig = this.saveConfig;
        await this.fetchCompaniesInKanban();
    }
    getcompaniesTotvsExistsCount() {
        //@ts-ignore
        return this.companiesTotvsExistsCount || 0;
    }
    toggleBtnUpdateTotvsData() {
        return {
            updateTotvsData: this.updateTotvsData,
            //@ts-ignore
            companiesCNPJsTotvsExists: json(this.companiesCNPJsTotvsExists),
        };
    }
    exceededCRMLimit() {
        return exceedLimit(this.currentTab, 
        //@ts-ignore
        this.exportStats.companies, 
        //@ts-ignore
        this.exportStats.decisors, this.filters.decisors);
    }
    changedDecisorsFilter(current) {
        this.$emit('changedDecisorsFilter', current);
    }
    emitFields(list) {
        this.$emit('updateFields', list);
    }
    updateCalculate() {
        this.$emit('updateCalculate');
    }
    emitLeads(leadStatus) {
        this.$emit('updateLeads', leadStatus);
    }
    setSaveConfig() {
        this.$emit('setSaveConfig', this.toggleSaveConfig);
    }
    get isSheet() {
        return ['csv', 'excel'].includes(this.currentTab);
    }
    get showDecisorFilters() {
        const sheetCondition = this.exportType == 'decisors' && this.isSheet;
        const crmCondition = this.filters.decisors && !this.isSheet;
        return sheetCondition || crmCondition;
    }
};
__decorate([
    Prop({ default: () => { } })
], InfosetExportContent.prototype, "documentType", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetExportContent.prototype, "currentTab", void 0);
__decorate([
    Prop({ default: false })
], InfosetExportContent.prototype, "disabledToggleSaveFiltersAndFields", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetExportContent.prototype, "currentFields", void 0);
__decorate([
    Prop({ default: () => false })
], InfosetExportContent.prototype, "saveConfig", void 0);
__decorate([
    PropSync('currentFilters', { default: () => { } })
], InfosetExportContent.prototype, "filters", void 0);
__decorate([
    PropSync('currentService', { default: 'summary' })
], InfosetExportContent.prototype, "service", void 0);
__decorate([
    PropSync('currentType', { default: 'companies' })
], InfosetExportContent.prototype, "exportType", void 0);
__decorate([
    PropSync('keepRedundancy', { default: () => false })
], InfosetExportContent.prototype, "keepDataRedundancy", void 0);
__decorate([
    Prop({ default: false })
], InfosetExportContent.prototype, "calculateCompaniesOnTotvsFetching", void 0);
__decorate([
    Prop({ default: '' })
], InfosetExportContent.prototype, "infoset_id", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetExportContent.prototype, "candencesList", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetExportContent.prototype, "ownerCustomFieldList", void 0);
__decorate([
    Prop({ default: () => [] })
], InfosetExportContent.prototype, "ownerUsersList", void 0);
__decorate([
    Prop({ default: 0 })
], InfosetExportContent.prototype, "exportedCnpjForMeetime", void 0);
__decorate([
    Prop({ default: () => false })
], InfosetExportContent.prototype, "missingCnpjMeetimeField", void 0);
__decorate([
    Emit('cadenceSelected')
], InfosetExportContent.prototype, "cadenceSelected", null);
__decorate([
    Emit('ownerSelected')
], InfosetExportContent.prototype, "ownerSelected", null);
__decorate([
    Emit('toggleBtnUpdateTotvsData')
], InfosetExportContent.prototype, "toggleBtnUpdateTotvsData", null);
InfosetExportContent = __decorate([
    Component(connect(({ state: { infosets: infosetsState }, actions, effects }) => ({
        exportStats: infosetsState.exportStats,
        companiesTotvsExistsCount: infosetsState.companiesTotvsExistsCount,
        companiesCNPJsTotvsExists: infosetsState.companiesCNPJsTotvsExists,
        effects: effects.companies,
    }), {
        components: {
            ExportationsFields,
            ExportationsDecisors,
            ExportationsCompanyStatus,
            ExportationsLeadStatus,
            ExportationsTypes,
            InfosetExportErrorMessage,
            Tooltip,
        },
    }))
], InfosetExportContent);
export default InfosetExportContent;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import Header from '@/modules/infosets/components/Exportations/InfosetExportHeader.vue';
import InfosetExportContent from '@/modules/infosets/components/Exportations/InfosetExportContent.vue';
import { connect } from '@/overmind';
import axios from 'axios';
let InfosetExportTabs = class InfosetExportTabs extends Vue {
    constructor() {
        super(...arguments);
        this.loaded = false;
        this.companiesCNPJsTotvsExists = [];
        this.updateTotvsData = false;
        this.keepRedundancy = false;
        this.service = 'excel';
        this.exportType = 'companies';
        this.fields = {};
        this.filters = {
            companiesSuccessStatus: false,
            levels: [],
            departments: [],
            decisors: true,
            decisors_with: [],
        };
        this.candencesList = [];
        this.ownerUsersList = [];
        this.ownerCustomFieldList = [];
        this.saveFiltersAndFields = false;
        this.disabledToggleSaveFiltersAndFields = false;
        this.cadenceApiUrl = '';
        this.ownerSelected = {};
        this.missingCnpjMeetimeField = false;
    }
    getcalculateCompaniesOnTotvsFetching() {
        //@ts-ignore
        return this.calculateCompaniesOnTotvsFetching;
    }
    setCandenceApiUrl(candenceUrl) {
        this.cadenceApiUrl = candenceUrl;
    }
    setOwnerSelected(ownerSelected) {
        this.ownerSelected = ownerSelected;
    }
    updateFilterCompaniesStatus() {
        this.calculateExports();
    }
    async setSaveConfig(value) {
        this.saveFiltersAndFields = value;
        this.disabledToggleSaveFiltersAndFields = true;
        if (value) {
            await this.saveExportationFiltersAndFields();
        }
        else {
            //@ts-ignore
            await this.userActions.deleteExportationFieldsAndFiltersSaved();
        }
        this.disabledToggleSaveFiltersAndFields = false;
    }
    async calculateExistingCompaniesOnTotvs() {
        // @ts-ignore
        await this.actions.calculateExistingCompaniesOnTotvs({
            infosetId: this.infoset.id,
        });
    }
    async getCandencesList(token) {
        const result = await axios.get('https://api.meetime.com.br/v2/cadences', {
            headers: {
                Authorization: token,
            },
        });
        if (result && result.status == 200) {
            const cadences = result.data.data || [];
            this.candencesList = cadences.filter(c => ['', null].includes(c.deleted));
        }
    }
    async checkCustomFields(token) {
        try {
            const userLogged = localStorage.getItem('userEmailLogged') || '';
            const result = await axios.get('https://api.meetime.com.br/v2/leads/custom-fields', {
                headers: { Authorization: token },
            });
            if (!result || !result.data || !result.data.data)
                return;
            const fields = result.data.data;
            this.missingCnpjMeetimeField = !fields.find(f => f.field_key == 'cnpj');
            if (userLogged.match('@sankhya') /*|| userLogged.match('@speedio')*/) {
                const filterResult = fields.find(l => l.field_key == 'sdrResponsavel');
                this.ownerCustomFieldList = filterResult.options;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    async getOwnerUsersList(token) {
        try {
            const result = await axios.get('https://api.meetime.com.br/v2/users', {
                headers: { Authorization: token },
            });
            if (result && result.data) {
                const resultData = result.data.data;
                const filterResult = resultData.filter(l => l.email && ['', null].includes(l.deleted_on));
                this.ownerUsersList = filterResult;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    async mounted() {
        const token = localStorage.getItem('tokenMeetime') || '';
        if (token) {
            await this.getCandencesList(token);
            await this.checkCustomFields(token);
            await this.getOwnerUsersList(token);
        }
        // @ts-ignore
        const response = await this.userActions.recoverExportationFieldsAndFilters();
        if (response) {
            this.saveFiltersAndFields = true;
            this.currentTab = response.selected_tab;
            this.service = response.selected_service;
            this.fields = response.selected_fields;
            if (response.selected_filters) {
                const { decisors, decisors_with, departments, levels } = response.selected_filters;
                this.filters.decisors = decisors;
                this.filters.decisors_with = decisors_with;
                this.filters.departments = departments;
                this.filters.levels = levels;
            }
            this.setExportType();
            this.$emit('changeDecisorsFilter', response.selected_filters.decisors || false);
        }
        else {
            this.currentTab = 'excel';
            this.exportType = 'companies';
        }
        this.calculateExports();
        this.loaded = true;
    }
    setExportType() {
        if (this.service.includes('excel') || this.service.includes('csv')) {
            this.exportType = this.service.split('-')[1] || 'companies';
        }
    }
    async saveExportationFiltersAndFields() {
        const config = {
            current_tab: this.currentTab,
            service: this.service,
            fields: this.fields,
            filters: this.filters,
        };
        //@ts-ignore
        await this.userActions.saveExportationFieldsAndFilters(config);
    }
    resetFilters() {
        this.filters.levels = [];
        this.filters.departments = [];
        this.filters.decisors_with = [];
    }
    manageFields(list) {
        const arr = {};
        list.map(el => {
            arr[el.name] = el.checkbox;
        });
        this.fields = arr;
    }
    manageLeads(leadStatus) {
        if (leadStatus) {
            this.fields['Status'] = leadStatus;
        }
        else {
            delete this.fields['Status'];
        }
    }
    async resetDecisorFilters(val, oldval) {
        if (['excel', 'csv'].includes(val)) {
            this.service = val + '-' + this.exportType;
        }
        else {
            this.service = val;
        }
        if (val === 'totvs') {
            await this.calculateExistingCompaniesOnTotvs();
        }
        if (['excel', 'csv'].includes(oldval) && ['excel', 'csv'].includes(val)) {
            return;
        }
        if (oldval != '') {
            this.resetFilters();
            this.exportType = 'companies';
        }
        this.calculateExports();
    }
    setServiceByExportType(val, oldval) {
        this.service = this.currentTab + '-' + val;
        if (oldval == 'decisors')
            this.resetFilters();
        this.calculateExports();
    }
    changedDecisorsFilter(value) {
        this.filters.decisors = value;
        this.filters.levels = [];
        this.filters.departments = [];
        this.filters.decisors_with = [];
        this.calculateExports();
        if (this.fields['Decisores'] !== null)
            this.fields['Decisores'] = value;
        this.$emit('changeDecisorsFilter', value);
    }
    setBtnUpdateTotvsData({ updateTotvsData, companiesCNPJsTotvsExists, }) {
        this.updateTotvsData = updateTotvsData;
        this.companiesCNPJsTotvsExists = companiesCNPJsTotvsExists;
    }
    async calculateExports() {
        // @ts-ignore
        await this.actions.calculateExports({
            // @ts-ignore
            infosetId: this.infoset.id,
            payload: {
                filters: this.filters,
                service: this.service,
            },
        });
    }
    async btnClicked() {
        const tab = this.currentTab;
        this.btnLoading = true;
        if (this.saveFiltersAndFields) {
            this.saveExportationFiltersAndFields();
        }
        const payload = {
            service: this.service,
            filters: this.filters,
            fields: this.fields,
            keep_redundancy: this.keepRedundancy,
        };
        let result;
        switch (this.currentTab) {
            case 'excel':
            case 'csv':
                // @ts-ignore
                result = await this.actions.exportData({
                    infosetId: this.infoset.id,
                    infosetName: this.infoset.name,
                    payload,
                });
                break;
            case 'salesforce':
                // @ts-ignore
                result = await this.userActions.exportSalesForce({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'rdstation':
                // @ts-ignore
                result = await this.userActions.exportRDStation({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'pipedrive':
                // @ts-ignore
                result = await this.userActions.exportPipedrive({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'ploomes':
                // @ts-ignore
                result = await this.userActions.exportPloomes({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'hubspot':
                // @ts-ignore
                result = await this.userActions.exportHubspot({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'bitrix24':
                // @ts-ignore
                result = await this.userActions.exportBitrix24({
                    infosetId: this.infoset.id,
                    payload: {
                        ...payload,
                        token: localStorage.getItem('urlBitrix24'),
                    },
                });
                break;
            case 'agendor':
                // @ts-ignore
                result = await this.userActions.exportAgendor({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'piperun':
                // @ts-ignore
                result = await this.userActions.exportPiperun({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'totvs':
                // @ts-ignore
                result = await this.userActions.exportToTvs({
                    infosetId: this.infoset.id,
                    payload: {
                        ...payload,
                        updateTotvsData: this.updateTotvsData,
                        companiesCNPJsTotvsExists: this.companiesCNPJsTotvsExists,
                    },
                });
                break;
            case 'funildevendas':
                // @ts-ignore
                result = await this.userActions.exportFunilDeVendas({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'zoho':
                // @ts-ignore
                result = await this.userActions.exportZoho({
                    infosetId: this.infoset.id,
                    payload,
                });
                break;
            case 'meetime':
                // @ts-ignore
                result = await this.userActions.exportMeetime({
                    infosetId: this.infoset.id,
                    payload,
                    cadenceApiUrl: this.cadenceApiUrl,
                    ownerSelected: this.ownerSelected,
                });
                break;
        }
        this.handleResult(result, tab);
    }
    handleResult(result, tab = '') {
        if (result && [200, 201].includes(result.status)) {
            this.$q.notify({
                message: result.data.message,
                type: 'exportation-success',
            });
            if (this.currentTab !== 'salesforce') {
                this.$emit('addToLoadingQueue', tab, result.data.event._id);
            }
        }
        else {
            this.$q.notify({
                message: result && result.data && result.data.message
                    ? result.data.message
                    : 'Aconteceu algo inesperado e não foi possível exportar sua lista !',
                type: 'exportation-error',
            });
        }
        this.btnLoading = false;
    }
};
__decorate([
    PropSync('buttonLoading', { default: () => { } })
], InfosetExportTabs.prototype, "btnLoading", void 0);
__decorate([
    PropSync('tab', { default: '' })
], InfosetExportTabs.prototype, "currentTab", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetExportTabs.prototype, "documentType", void 0);
__decorate([
    Prop({ default: 0 })
], InfosetExportTabs.prototype, "exportedCnpjForMeetime", void 0);
__decorate([
    Prop({ default: () => { } })
], InfosetExportTabs.prototype, "infoset", void 0);
__decorate([
    Watch('filters.companiesSuccessStatus')
], InfosetExportTabs.prototype, "updateFilterCompaniesStatus", null);
__decorate([
    Watch('currentTab')
], InfosetExportTabs.prototype, "resetDecisorFilters", null);
__decorate([
    Watch('exportType')
], InfosetExportTabs.prototype, "setServiceByExportType", null);
InfosetExportTabs = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.infosets,
        userActions: actions.users,
        calculateCompaniesOnTotvsFetching: state.users.calculateCompaniesOnTotvsFetching,
    }), {
        name: 'InfosetExportTabs',
        components: {
            Header,
            InfosetExportContent,
        },
    }))
], InfosetExportTabs);
export default InfosetExportTabs;
